<template>
  <div class="top-banner">
    <gradient-delimiter height="32px" start-color="rgba(245, 245, 246, 1)" end-color="rgba(230, 230, 230, 1)"/>
    <v-container>
      <div class="banner-image">
        <img :src="image_x1" :srcset="image_x2+' 2x'" :alt="description" :title="description">
      </div>
      <div class="banner-text">
        <slot/>
      </div>
    </v-container>
  </div>

</template>

<script>
import GradientDelimiter from "@/components/common/gradient-delimiter";

export default {
  name: "top-banner",
  components: {GradientDelimiter},
  props: ['image_x1', 'image_x2', 'description'],
}
</script>

<style scoped>
.top-banner {
  background-color: #f5f5f6;
}

.top-banner .container {
  position: relative;
}

.banner-image {
  position: relative;
  overflow: hidden;
  right: 0;
  z-index: 0;
  line-height: 0;
}

.banner-image img {
  width: 100%;
}

.banner-text {
  position: absolute;
  color: #333;
  margin-bottom: 40px;
  z-index: 1;
  top: 40px;
  width: 100%;
}

.banner-text h1 {
  letter-spacing: 0;
  text-align: left;
  font-size: 40px;
}

@media screen and (max-width: 575px) {
  .banner-text h1 {
    text-align: center;
  }

  .banner-text {
    position: relative;
    text-align: center;
  }

  .banner-image {
    position: absolute;
    bottom: 0;
    opacity: 0.15;
    width: 200%;
  }
}

</style>