import { render, staticRenderFns } from "./gray-brick.vue?vue&type=template&id=49f19928&scoped=true&"
import script from "./gray-brick.vue?vue&type=script&lang=js&"
export * from "./gray-brick.vue?vue&type=script&lang=js&"
import style0 from "./gray-brick.vue?vue&type=style&index=0&id=49f19928&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49f19928",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
