<template>
  <v-card :min-height="mh" height="100%" flat color="#f5f5f6" rounded="0">
    <div class="brick-text" :class="image ? 'with-image' : ''">
      <h3 v-if="title" v-html="title"></h3>
      <slot/>
    </div>
    <img v-if="image" :src="image" :alt="image" class="brick-image">
  </v-card>
</template>

<script>
export default {
  name: "gray-brick",
  props: ['image', 'title', 'minHeight'],
  computed: {
    mh () { return this.minHeight ? this.minHeight : "100"; },
  }
}
</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.theme--light.v-sheet {
  position: relative;
  padding: 30px;
}

.brick-image {
  position: absolute;
  bottom: 0;
  right: 0;
}

h3 {
  line-height: 25px;
  margin-bottom: 20px;
}

/*noinspection CssUnusedSymbol*/
.brick-text.with-image {
  width: 45%;
}

@media screen and (max-width: 575px) {
  .brick-image {
    opacity: 0.15;
    z-index: 0;
  }

  .brick-text {
    z-index: 1;
  }

  /*noinspection CssUnusedSymbol*/
  .brick-text.with-image {
    width: 100%;
  }
}
</style>