<template>
  <footer>
    <v-container>
      <v-row no-gutters class="mb-4">
        <v-col md="9">
          <div><slot/></div>
          <div class="tks-links">
            <a v-for="link in tksLinks" :key="link.text" :href="link.link" class="d-inline-block">{{ link.text }}</a>
          </div>
          <div class="product-links">
            <a v-for="link in productLinks" :key="link.text" :href="link.link" target="_blank" class="d-inline-block">{{ link.text }}</a>
          </div>
        </v-col>
        <v-col md="auto" cols="12" align-self="center" class="text-right mt-8 mt-md-0" style="flex: auto">
          <div class="phone">{{ phone }}</div>
          <span class="call-free">Бесплатно по России</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="bottom py-4">
        <v-col cols="auto">
          АО «Тинькофф Банк» <a href="https://www.tinkoff.ru/about/news/06102020-tinkoff-the-third-largest-bank-in-russia-number-of-clients" target="_blank" class="mr-0">третий в России банк</a> по востребованности использования клиентами в розничном сегменте среди 30 банков по исследованиям АО «Делойт и Туш СНГ», Frost& Sullivan, «НЭО Центра», Ipsos
        </v-col>
      </v-row>
      <v-row no-gutters class="bottom py-4 mb-4">
        <v-col cols="auto" align-self="center">
          © 2006–{{ year }}, АО «Тинькофф Банк», официальный сайт, лицензия ЦБ РФ № 2673<br>
          1-й Волоколамский проезд, д. 10, стр. 1, Москва, 123060 Россия
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
export default {
  name: "tks-footer",

  data: () => ({
    tksLinks: [
      {link: 'https://www.tinkoff.ru/about/', text: 'ТИНЬКОФФ'},
      {link: 'https://www.tinkoff.ru/', text: 'БАНК'},
      {link: 'https://www.tinkoff.ru/business/', text: 'БИЗНЕС'},
      {link: 'https://www.tinkoff.ru/invest/', text: 'ИНВЕСТИЦИИ'},
      {link: 'https://www.tinkoff.ru/insurance/', text: 'СТРАХОВАНИЕ'},
      {link: 'https://www.tinkoff.ru/mobile-operator/', text: 'СИМ-КАРТА'},
      {link: 'https://www.tinkoff.ru/travel/', text: 'ПУТЕШЕСТВИЯ'},
      {link: 'https://www.tinkoff.ru/entertainment/', text: 'РАЗВЛЕЧЕНИЯ'},
    ],
  }),

  props: ['phone', 'productLinks'],

  computed: {
    year () { return new Date().getFullYear() },
  }
}
</script>

<style scoped>
footer {
  background-color: #f5f5f6;
  padding-top: 20px;
}

.bottom {
  color: #909090;
  font-size: 13px;
  border-top: 1px solid #dddddd;
}

.phone {
  font-size: 26px;
  line-height: 28px;
  font-family: TinkoffSans, pfhighway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: -.3px;
  color: black;
}

.call-free {
  font-size: 13px;
  color: #909090;
}

.tks-links {
  margin-bottom: 10px;
}

.v-application a {
  text-decoration: none;
  font-size: 13px;
  margin-right: 20px;
}

.v-application .tks-links a {
  color: #0000008a;
}

.v-application .tks-links a:hover {
  color: #000000;
}

.v-application .product-links a {
  color: #0050f1;
}

.v-application .product-links a:hover {
  color: #1f50ba;
}
</style>