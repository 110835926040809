<template>
    <div :style="style"></div>
</template>

<script>
export default {
  name: 'gradient-delimiter',
  props: ['height', 'startColor', 'endColor'],
  computed: {
    style () {
      return {
        '--start-color': this.startColor,
        '--end-color': this.endColor,
        '--height': this.height,
      }
    }
  },
}
</script>

<style scoped>
/*noinspection CssUnresolvedCustomProperty*/
div {
  height: var(--height);
  background: var(--start-color);
  background: linear-gradient(0deg, var(--start-color) 0%, var(--end-color) 100%);
}
</style>