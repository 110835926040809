<template>
  <header>
    <v-container class="header-container">
      <v-row no-gutters>
        <v-col>
          <img src="../../assets/images/common/tinkoff-logo-text.svg" alt="LOGO" width="150" height="34">
        </v-col>
        <v-col cols="auto" align-self="center">
          <slot></slot>
        </v-col>
        <v-col align-self="center" class="text-right">
          <a :href="'tel:'+phone" class="phone">{{ phone }}</a>
        </v-col>
      </v-row>
    </v-container>
  </header>
</template>

<script>
export default {
  name: "tks-header",
  props: ['phone'],
}
</script>

<style scoped>
header {
  background-color: #ffffff;
  line-height: 1;
}

.phone {
  text-decoration: none;
}

.header-container {
  padding-top: 13px;
  padding-bottom: 13px;
}
</style>