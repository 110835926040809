<template>
  <v-app>
    <site-header :phone="phone"/>
    <top-banner :image_x1="require('@/assets/images/top-banner-x1.png')" :image_x2="require('@/assets/images/top-banner-x2.png')" description="Описание банера">
      <h1>Бесплатная банковская<br> карта для детей</h1>
      <div class="my-8 col-md-6 pa-0 mb-md-16 mb-8">Лучше наличных: помогает присматривать<br> за тратами ребенка, приносит бонусы<br> за покупки, учит финграмотности</div>
      <yellow-button @action="$vuetify.goTo('iframe')" class="d-block d-md-inline-block mx-auto mt-md-8 mt-0">Оформить карту</yellow-button>
    </top-banner>

    <v-container>
      <h2 class="mb-4">Детям до 14… разрешается</h2>
      <span class="text-center d-block mb-10">Детская карта — полезная и с дизайном на выбор. Будет привязана к счету родителя</span>
      <v-row>
        <v-col md="7">
          <gray-brick title="Траты под присмотром" :image="require('@/assets/images/brick-image.webp')">
            Операции видны в детском и взрослом приложениях. Можно установить лимиты
          </gray-brick>
        </v-col>
        <v-col md="5">
          <gray-brick title="Финграмотность с детства">
            Истории, комиксы и тесты в приложении «Джуниор» научат ребенка обращаться с деньгами
          </gray-brick>
        </v-col>
        <v-col md="5">
          <gray-brick title="0 ₽ за обслуживание детской карты">
            СМС родителям о тратах ребенка и переводы на детскую карту — также бесплатно
          </gray-brick>
        </v-col>
        <v-col md="7">
          <gray-brick title="Первые сбережения и первый доход">
            Счет-копилка с доходом до 8% годовых и бонусы за покупки: до 30% по акциям, 2% за онлайн-покупки и 1% за все остальные. Бонусы тратятся по курсу 1 бонус = 1 ₽
          </gray-brick>
        </v-col>
      </v-row>
      <h2 class="mb-8">Оформите карту за&nbsp;5&nbsp;минут,<br class="d-none d-md-inline"> получите за&nbsp;2&nbsp;дня</h2>
      <div class="text-center mb-8">Взрослым без карты Тинькофф выпустим нашу лучшую дебетовую карту,<br class="d-none d-md-inline"> чтобы бесплатно переводить деньги ребенку</div>
    </v-container>

    <app-form :url="form.url" :tiktok-token="form.tiktokToken" :tiktok-events="form.tiktokEvents"/>

    <v-container class="gray pb-16 mt-8">
      <v-row justify="center">
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="(item,i) in faq" :key="i">
            <v-expansion-panel-header>
              <div v-html="item.q"></div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html="item.a"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>

    <site-footer :phone="phone" :product-links="productLinks"/>
  </v-app>
</template>

<script>
import SiteHeader from '@/components/common/site-header';
import SiteFooter from '@/components/common/site-footer';
import AppForm from '@/components/common/app-form';
import TopBanner from '@/components/common/top-banner';
import YellowButton from "@/components/common/yellow-button";
import GrayBrick from "@/components/common/gray-brick";

export default {
  name: 'App',
  components: {GrayBrick, YellowButton, TopBanner, SiteFooter, SiteHeader, AppForm},

  data: () => ({
    form: {
      url: 'https://www.tinkoff.ru/cards/debit-cards/tinkoff-black/junior/iframe/',
      tiktokToken: 'C49OQT3UL0H8SJ1HKS5G',
      tiktokEvents: [
        {ttq: 'AddToCart', form: 'formAutosave'},
        {ttq: 'PlaceAnOrder', form: 'formCompleted'},
        {ttq: 'CompletePayment', form: 'formAppointmentSubmit'},
      ],
    },

    faq: [
      {
        q: '<h3>Заявка отправлена. Что дальше?</h3>',
        a: 'Мы доставим карту «Тинькофф Джуниор» владельцу счета, то есть родителю, чьи данные указаны в заявке. Детям до 14 лет присутствовать на встрече необязательно. Несовершеннолетние не могут сами получить карту, так как только взрослые имеют право заключить договор с банком.'
      },
      {
        q: '<h3>Чем детская карта отличается от обычной и дополнительной?</h3>',
        a: 'Карта и приложение «Тинькофф Джуниор» разработаны специально для детей. Они учат финансовой грамотности и базовым операциям с деньгами. «Истории» в приложении объясняют простым языком, как пользоваться картой и услугами банка, советуют идеи для учебы и отдыха. Если ребенку что-то непонятно, вам не нужно думать над объяснением: подготовленные сотрудники банка ответят на вопросы ребенка в чате.'
      },
      {
        q: '<h3>Где можно расплачиваться детской картой?</h3>',
        a: 'Тинькофф Джуниор — полноценная дебетовая карта. Дети оплачивают ею покупки в магазинах и в интернете, подписки на сервисы. В приложении «Джуниор» доступны билеты в кино с кэшбэком, платежи за мобильную связь и интернет, переводы денег друзьям. Снять наличные можно в любом банкомате. Родители контролируют детские траты, устанавливая лимиты.'
      },
      {
        q: '<h3>Как потратить бонусные баллы?</h3>',
        a: 'Накопленными баллами дети компенсируют покупки в интернет-магазинах, кафе, фастфуде, столовых и ресторанах, если с момента обработки покупок банком прошло не больше 90 дней.<br><br>Компенсировать можно только сумму покупки целиком по курсу 1 балл = 1 ₽. Оплатить покупку сразу баллами нельзя.'
      },
      {
        q: '<h3>Как родитель сможет увидеть траты ребенка?</h3>',
        a: 'Если у родителя уже есть любая дебетовая карта Тинькофф, в его приложении появится детский счет сразу после оформления Тинькофф Джуниор. Если нет, мы бесплатно доставим сразу две дебетовых карты: Тинькофф Джуниор для ребенка и Tinkoff Black для взрослого.<br><br>Остаток на детской карте и все операции по ней отображаются в мобильном приложении и личном кабинете родителя. Чтобы сразу узнавать о тратах ребенка, подключите СМС-уведомления — это бесплатно.'
      },
      {
        q: '<h3>Какое задание можно дать ребенку? Как это работает?</h3>',
        a: 'Родители могут не просто пополнять карту ребенка, но и присылать задания с денежным вознаграждением. Взрослый создает задание в своем приложении, а когда ребенок его выполняет, родитель получает уведомление и переводит обещанную сумму нажатием одной кнопки. Задания и суммы могут быть любыми: например, убрать в комнате за 200 ₽ или закончить четверть без троек за 5000 ₽. Вознаграждение за задание списывается со счета родителя.'
      },
    ],
    productLinks: [
      {link: 'https://acdn.tinkoff.ru/static/documents/loyalty-program-target_rules.pdf', text: 'Правила программы лояльности Таргет'},
      {link: 'https://acdn.tinkoff.ru/static/documents/debit-tariffs-tps_3_0.pdf', text: 'Тарифный план для родителя'},
      {link: 'https://acdn.tinkoff.ru/static/documents/016a7853-cd29-4330-8ceb-0b5d7a68e4ce.pdf', text: 'Накопительный счет'},
      {link: 'https://acdn.tinkoff.ru/static/documents/debit-tariffs-tps_1_1.pdf', text: 'Тарифный план Тинькофф Джуниор'},
      {link: 'https://acdn.tinkoff.ru/static/documents/loyalty-program-rules_junior.pdf', text: 'Правила Тинькофф Джуниор'},
      {link: 'https://acdn.tinkoff.ru/static/documents/627ee150-0276-4a81-9d0c-8be1e265ae87.pdf', text: 'Условия комплексного банковского обслуживания'},
      {link: 'https://acdn.tinkoff.ru/static/documents/docs-dataagreement.pdf', text: 'Условия передачи информации'},
    ],
    phone: process.env.VUE_APP_PHONE,
  }),

};
</script>

<style>

@font-face {
  font-family: 'TinkoffSans';
  src: url('assets/fonts/TinkoffSans.woff2');
}

@font-face {
  font-family: 'haas';
  src: url('assets/fonts/NeueHaasUnicaW1G-Regular.woff2');
}

/*noinspection CssUnusedSymbol*/
.v-application {
  -webkit-font-smoothing: antialiased;
  font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
  font-feature-settings: "kern", "liga" 0, "clig" 0 !important;
  font-kerning: normal !important;
  font-size: 15px !important;
  font-stretch: 100% !important;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  color: #333333;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
}

/*noinspection CssUnusedSymbol*/
.container {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 1904px) {
  /*noinspection CssUnusedSymbol*/
  .container {
    max-width: 1185px;
  }
}

h1, h2, h3 {
  font-family: 'TinkoffSans', sans-serif;
  font-weight: 400;
}

h1 {
  text-align: center;
  font-size: 36px;
  line-height: 40px;
}

h2 {
  margin-top: 48px;
  margin-bottom: 24px;
  text-align: center;
  font-size: 36px;
  line-height: 40px;
}

h3 {
  font-size: 25px;
  line-height: 30px;
}

a {
  text-decoration: none;
  color: #1771e6;
}

a:hover {
  color: #1464cc;
}

.w100 {
  width: 100%;
}

::selection {
  background: rgba(255, 221, 45, .32) !important;
  color: rgba(0, 0, 0, .8) !important;
}

/*noinspection CssUnusedSymbol*/
.v-expansion-panels {
  margin-left: 240px;
  margin-right: 240px;
}

/*noinspection CssUnusedSymbol*/
.v-expansion-panels h3 {
  font-size: 22px;
}

@media (min-width: 1904px) {
  .container {
    max-width: 1185px;
  }
}

@media screen and (max-width: 575px) {
  /*noinspection CssUnusedSymbol*/
  .v-expansion-panels {
    margin-left: 0;
    margin-right: 0;
  }

  .top-banner {
    padding-bottom: 32px;
  }
}

</style>